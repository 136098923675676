<!-- 餐饮订单-部分退款 -->

<template>
  <div class="partial-refund-dialog">
    <w-dialog ref="dialogRef" title="退款" width="30%" top="10vh" @wConfirm="handleSure" confirmText="确认退款">
      <div class="refund-title">{{ currentRow.drg_name }}</div>
      <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <el-form-item prop="odrr_amount" label="退款数量">
          <el-input v-model="formData.odrr_amount" type="number" placeholder="请输入退款数量" clearable
            @input="inputBlur('num')" @blur="inputBlur('num')"></el-input>
        </el-form-item>

        <el-form-item prop="amount_money" label="商品金额">
          <el-input v-model="formData.amount_money" type="number" placeholder="请输入商品金额" clearable
            @input="inputBlur('money')" @blur="inputBlur('num')"></el-input>
        </el-form-item>

        <el-form-item prop="odrr_pack_total" label="打包费">
          <el-input v-model="formData.odrr_pack_total" type="number" placeholder="请输入打包费" readonly></el-input>
        </el-form-item>

        <el-form-item prop="radio" label="是否退打包费">
          <el-radio-group v-model="formData.radio" @change="radioChange">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item prop="refund_money" label="退款合计">
          <el-input v-model="formData.refund_money" type="number" placeholder="请输入退款合计" readonly></el-input>
        </el-form-item>
      </el-form>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, nextTick } from "vue";
  import { ElMessage, } from "element-plus";

  export default {
    emits: ["submit"],
    setup(props, { emit }) {
      const dialogRef = ref(null);  // 对话框对象
      const formRef = ref(null);  // 表单对象
      const formData = ref({  // 表单内容
        refund_mode: 2,  // 退款模式1：全额退款2部分退款
        odrgd_id: '',  // 餐饮订单明细id（只针对部分退单）
        odrr_amount: '',  // 退款数量
        refund_money: '',  // 退款合计
        amount_money: '',  // 退款金额
        odrr_pack_total: '',  // 退款打包金额
        radio: 1,  // 是否退款打包费
      });
      const formRules = {  // 表单验证
        amount_money: [
          { required: true, message: "请输入退款金额", trigger: "blur", },
        ],
        odrr_amount: [
          { required: true, message: "请输入退款数量", trigger: "blur", },
        ],
      };
      const currentRow = ref(null);  // 当前操作行数据
      /**
       * 
       * 打开对话框
       * 
       * **/
      const openDialog = (row) => {
        currentRow.value = row;
        // 计算打包费：打包盒数量*打包盒单价*退款数量
        formData.value.odrgd_id = row.odrgd_id;
        formData.value.refund_money = '';
        formData.value.odrr_amount = row.odrgd_amount;
        formData.value.odrr_pack_total = Number(row.drg_bag_number) * Number(row.bag_price) * Number(row.odrgd_amount);
        formData.value.amount_money = '';
        formData.value.radio = 1;
        dialogRef.value.show();
      }
      /**
       * 
       * 关闭对话框
       * 
       * **/
      const closeDialog = () => {
        dialogRef.value.close();
      }
      /**
       * 
       * 关闭对话框加载状态
       * 
       * **/
      const closeDialogLoading = () => {
        dialogRef.value.isLoading = false;
      }
      /**
       * 
       * 输入框失去焦点
       * 
       * **/
      const inputBlur = (type) => {
        if (type == 'num') {
          // 数量
          if (!formData.value.odrr_amount) {
            formData.value.refund_money = 0;
          } else {
            formData.value.odrr_pack_total = Number(currentRow.value.drg_bag_number) * Number(currentRow.value.bag_price) * Number(formData.value.odrr_amount);
            radioChange();
          }
        } else {
          // 金额
          if (!formData.value.amount_money) {
            formData.value.refund_money = 0;
          } else {
            radioChange();
          }
        }
      }
      /**
       * 
       * 是否退打包费
       * 
       * **/
      const radioChange = () => {
        if (formData.value.radio == 1) {
          // 退打包费
          formData.value.refund_money = Number(formData.value.amount_money) + Number(formData.value.odrr_pack_total);
        } else {
          // 不退打包费
          formData.value.refund_money = formData.value.amount_money;
        }
      }
      /**
       * 
       * 提交
       * 
       * **/
      const handleSure = () => {
        if (formRef.value) {
          formRef.value.validate((valid) => {
            if (valid) {
              dialogRef.value.isLoading = true;
              const data = JSON.parse(JSON.stringify(formData.value));
              emit("submit", data);
            }
          });
        }
      }

      return {
        formData,
        formRules,
        formRef,
        handleSure,
        openDialog,
        dialogRef,
        closeDialog,
        currentRow,
        closeDialogLoading,
        inputBlur,
        radioChange,
      };
    },
  };
</script>

<style lang="scss">
  .partial-refund-dialog {
    .refund-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
      color: var(--text-color);
    }
  }
</style>