<!-- 餐厅管理-餐饮订单 -->

<template>
  <div class="catering-orders main-cnt">
    <div class="title">餐饮订单</div>
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 325px)" :apiName="DinnerApi.getDinnerOrder"
        :filters="filters" :columns="tableColumns" @onDetailsBtn="onDetailsBtn" @onPrint="onPrint">
        <template #pay_status="{ row }">
          <div class="status-text" :class="orderStatusColors(row.or_pay_status, 'pay')">{{ row.or_pay_status_text }}
          </div>
        </template>

        <template #odrg_status="{ row }">
          <div class="status-text" :class="orderStatusColors(row.odrg_status, 'work')">
            {{ row.odrg_status_text }}</div>
        </template>

        <!-- 退款 -->
        <template #fullRefund="{ row }">
          <template v-if="row.is_init_refund == 1 && authData.indexOf('r_ZnRkz001PzTFKL7EZ5jroG3Sa6In') != -1">
            <el-button class="red-font-btn" @click="onFullRefund(row)">退款</el-button>
          </template>
        </template>

      <template #print_stats="{ row }">
        <div class="status-text" :class="row.odrg_print_status == '1' ? 'btn-orange' : 'btn-grey'">{{ row.odrg_print_stats_text }}</div>
      </template>

        <!-- 取消退款 -->
        <template #cancelRefund="{ row }">
          <template v-if="row.is_cancel_refund == 1 &&authData.indexOf('r_l8HSIBbxJ6PcNLSxukFzm5sUmB1L') != -1">
            <el-popconfirm title="确定要取消该订单退款吗?" @confirm="onConfirmRefund(row)">
              <template #reference>
                <el-button class="theme-font-btn">取消退款</el-button>
              </template>
            </el-popconfirm>
          </template>
        </template>
      </common-table>
    </div>

    <!-- 详情 -->
    <w-dialog ref="infoDialogRef" class="order-info" title="订单详情" width="55%" btnWidth="140px" top="5vh"
      :hideFooter="true">
      <w-title :title_name="'订单详情'"></w-title>
      <div class="order-content">
        <el-table class="order-table" :data="infoDates.orderdetail" height="200" style="width: 100%" stripe
          tooltip-effect="light" ref="orderTableRef">
          <el-table-column label="商品">
            <template #default="scope">
              <div class="flex">
                <el-image style="width: 40px; height: 40px;margin-right: 10px;" :src="scope.row.drgf_path_text"
                  :preview-src-list="[scope.row.drgf_path_text]" fit="cover">
                </el-image>
                <div>{{ scope.row.drg_name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column property="odrgd_amount" label="数量"></el-table-column>
          <el-table-column property="odrgd_total" label="商品金额"></el-table-column>
          <el-table-column property="odrgd_pack_money" label="打包费"></el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <div class="cursor-pointer text-blue" @click="onPartialRefund(scope.row)"
                v-if="scope.row.is_init_refund == 1 && authData.indexOf('r_ZnRkz001PzTFKL7EZ5jroG3Sa6In') != -1">退款
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="order-fee flex-end align-center">
          <div class="order-fee-title">
            <div>打包费：</div>
            <div class="xs-m-t" v-if="infoDates.odrg_receive_way == '1'">配送费：</div>
            <div class="text-blue xs-m-t">总&nbsp&nbsp&nbsp计：</div>
          </div>
          <div class="order-fee-num">
            <div>￥{{ infoDates.odrg_pcharges }}</div>
            <div class="xs-m-t" v-if="infoDates.odrg_receive_way == '1'">￥{{ infoDates.odrg_deliver_fee }}</div>
            <div class="total-num text-blue xs-m-t">￥{{ infoDates.odrg_total }}</div>
          </div>
        </div>
      </div>

      <w-title :title_name="'退款详情'"></w-title>
      <common-table ref="refunTableRef" tableHeight="200" :columns="refundColumns" :tableData="infoDates.refun"
        :ispaging="false">
        <template #status="{ row }">
          <div class="status-text" :class="refundStatusColors(row.orr_status, 'color')">
            {{ refundStatusColors(row.orr_status, 'text') }}
          </div>
        </template>
      </common-table>

      <el-row :gutter="20">
        <el-col :span="12">
          <w-title :title_name="'订单信息'"></w-title>
          <div class="flex">
            <div class="catering-infor-title">下单时间</div>
            <div class="catering-infor-content">{{ infoDates.ctime_text }}</div>
          </div>
          <div class="flex">
            <div class="catering-infor-title">订单来源</div>
            <div class="catering-infor-content">{{ infoDates.odrg_source_text }}</div>
          </div>
          <div class="flex">
            <div class="catering-infor-title">下单用户</div>
            <div class="catering-infor-content">{{ infoDates.m_mobile }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <w-title :title_name="'配送信息'"></w-title>
          <div class="flex">
            <div class="catering-infor-title">配送地址</div>
            <div class="catering-infor-content">{{ infoDates.odrg_address }}</div>
          </div>
          <div class="flex">
            <div class="catering-infor-title">配送时间</div>
            <div class="catering-infor-content">
              <span>{{ infoDates.odrg_prospect_stime_text }}</span>
              <span v-if="infoDates.odrg_prospect_etime_text">至{{ infoDates.odrg_prospect_etime_text }}</span>
            </div>
          </div>
          <div class="flex" v-if="infoDates.odrg_receive_way == '1'">
            <div class="catering-infor-title">配送费用</div>
            <div class="catering-infor-content">{{ infoDates.odrg_deliver_fee }}</div>
          </div>
        </el-col>
      </el-row>
    </w-dialog>

    <!-- 退款 -->
    <PartialRefund ref="refundRef" @submit="handleSureRefund"></PartialRefund>
  </div>
</template>
<script setup>
  import { ref, onMounted, computed, watch, nextTick, } from "vue";
  import { DinnerApi, } from "@/plugins/api.js"; DinnerApi
  import { ElMessage, ElMessageBox, } from "element-plus";
  import { useStore } from "vuex";
  import { orderStatusColors, } from "@/utils/common.js";
  import { h } from 'vue';
  import PartialRefund from "../components/PartialRefund.vue";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /** 筛选条件列表 */
  const filters = ref([
    {
      filterType: "search",
      name: "kwz",
      value: "",
      placeholder: "请输入订单号进行查询",
    },
    {
      filterType: "select",
      name: "odrg_source",
      value: "",
      placeholder: "请选择订单来源",
      options: [
        { id: 1, label: '线上点餐' },
        { id: 2, label: '客房点餐' },
        { id: 3, label: '门店点餐' },
      ],
      val: "id",
      lab: "label",
    },
    {
      filterType: "select",
      name: "or_pay_status",
      value: "",
      placeholder: "请选择支付状态",
      options: [
        { id: 1, label: '待支付' },
        { id: 2, label: '已支付' },
        { id: 3, label: '已取消' },
        { id: 4, label: '申请退款中' },
        { id: 5, label: '已退款' },
      ],
      val: "id",
      lab: "label",
    },
    {
      filterType: "select",
      name: "odrg_status",
      value: "",
      placeholder: "请选择订单状态",
      options: [
        { id: 1, label: '待生效' },
        { id: 3, label: '待制作' },
        { id: 4, label: '制作中' },
        { id: 5, label: '已完成' },
        { id: 6, label: '已过期' },
        { id: 7, label: '已取消' },
      ],
      val: "id",
      lab: "label",
    },
  ]);
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "or_sn",
      label: "订单号",
      minWidth: 150,
    },
    {
      prop: "or_money",
      label: "订单金额",
    },
    {
      prop: "or_refund_money",
      label: "退款",
    },
    {
      prop: "or_yhmoney",
      label: "优惠合计",
    },
    {
      prop: "or_true_money",
      label: "实付金额",
    },
    {
      prop: "dr_name",
      label: "门店",
    },
    {
      prop: "odrg_source_text",
      label: "订单来源",
    },
    {
      prop: "ctime_text",
      label: "下单时间",
      minWidth: 120,
    },
    {
      prop: "pay_status",
      label: "支付状态",
      type: "customRender",
      minWidth: 80,
    },
    {
      prop: "odrg_status",
      label: "订单状态",
      type: "customRender",
      minWidth: 80,
    },
    {
      prop: "print_stats",
      label: "打印状态",
      type: "customRender",
      minWidth: 80,
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 270,
      bottons: [
        {
          name: "详情",
          action: "onDetailsBtn",
          token: "r_8mFVi2afZJbvI3x2fu0S0mFd2lcj",
          className: "theme-font-btn",
        },
        {
          type: "customRender",
          name: "退款",
          action: "fullRefund",
        },
        {
          name: "取消退款",
          action: "cancelRefund",
          type: "customRender",
        },
        {
          name: "打印",
          action: "onPrint",
          token: "r_WlMWhvVvnjW4l1htyftX1FITFU1x",
          HiddenKey: "or_pay_status",
          showValue: [2],
          className: "theme-font-btn",
        },
      ],
    },
  ]);
  const tableRef = ref(null);  // 餐饮表格对象
  const currentRow = ref('');  // 当前操作行数据
  const infoDialogRef = ref(null);  // 详情对象
  const infoDates = ref({});  // 详情数据
  const refunTableRef = ref(null);  // 退款表格对象
  const refundColumns = ref([
    {
      prop: "drg_name",
      label: "退款商品",
    },
    {
      prop: "drg_amount",
      label: "退款数量",
    },
    {
      prop: "drg_odrgd_total",
      label: "商品金额",
    },
    {
      prop: "odrr_pack_total",
      label: "打包费",
    },
    {
      prop: "refund_total",
      label: "退款合计",
    },
    {
      prop: "status",
      label: "退款状态",
      type: "customRender",
      minWidth: 120,
    },
  ]);
  const orderTableRef = ref(null);  // 订单详情对象
  const refundRef = ref(null);  // 部分退款对象
  /**
   * 
   * 详情按钮
   * 
   * **/
  const onDetailsBtn = (row) => {
    currentRow.value = row;
    getDinnerOrderInfo();
  }
  /**
   * 
   * 获取详情
   * 
   * **/
  const getDinnerOrderInfo = () => {
    DinnerApi.getDinnerOrderInfo({ odrg_id: currentRow.value.odrg_id }).then((res) => {
      if (res.Code === 200) {
        // console.log("详情", res);
        infoDates.value = res.Data;
        infoDialogRef.value.show();
        nextTick(() => {
          refunTableRef.value.tableLoad();
        });
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 全部退款按钮
   * 
   * **/
  const onFullRefund = (row) => {
    ElMessageBox.confirm("", "温馨提示", {
      confirmButtonText: "确认退款",
      cancelButtonText: "取消",
      message: h('p', null, [
        h('div', null, `你确定整单退款？`),
        h('div', null, '操作一经确认不可撤销！'),
      ]),
    })
      .then(() => {
        const lastMoney = +row.or_money - +row.or_refund_money;
        DinnerApi.dinnerInitiateRefund({
          refund_money: lastMoney,
          sn: row.or_sn,
          refund_mode: 1,
        }).then((res) => {
          if (res.Code === 200) {
            tableRef.value.tableLoad();
            ElMessage.success('退款成功！');
          } else {
            ElMessage.error(res.Message);
          }
        });
      })
      .catch(() => { });
  }
  /**
   * 
   * 部分退款按钮
   * 
   * **/
  const onPartialRefund = (row) => {
    refundRef.value.openDialog(row);
  }
  /**
   * 
   * 部分退款提交按钮
   * 
   * **/
  const handleSureRefund = (data) => {
    const parmas = {
      sn: currentRow.value.or_sn,
      ...data,
    };
    DinnerApi.dinnerInitiateRefund(parmas).then((res) => {
      refundRef.value.closeDialogLoading();
      if (res.Code === 200) {
        refundRef.value.closeDialog();
        getDinnerOrderInfo();
        tableRef.value.tableLoad();
        ElMessage.success("操作成功");
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 取消退款确定按钮
   * 
   * **/
  const onConfirmRefund = (row) => {
    DinnerApi.dinnerCancelRefund({ sn: row.or_sn }).then((res) => {
      if (res.Code === 200) {
        tableRef.value.tableLoad();
        ElMessage.success("取消退款成功！");
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 退款状态
   * 
   * **/
  const refundStatusColors = (status, type) => {
    let status_text = '';  // 状态（ 0-等待后台审核 1-退款中 2-已退款 3-退款失败 4取消退款订单 5拒绝退款 6人工退款已完成）
    let status_color = '';
    switch (status) {
      case 0:
        status_text = '待审核';
        status_color = 'btn-blue';
        break;
      case 1:
        status_text = '退款中';
        status_color = 'btn-yellow';
        break;
      case 2:
        status_text = '已退款';
        status_color = 'btn-black';
        break;
      case 3:
        status_text = '退款失败';
        status_color = 'btn-red';
        break;
      case 4:
        status_text = '取消退款';
        status_color = 'btn-grey';
        break;
      case 5:
        status_text = '拒绝退款';
        status_color = 'btn-red';
        break;
      case 6:
        status_text = '人工退款已完成';
        status_color = 'btn-orange';
        break;
    }

    if (type == 'text') {
      return status_text;
    } else {
      return status_color;
    }
  }
  /**
   * 
   * 打印按钮
   * 
   * **/
  const onPrint = (row) => {
    DinnerApi.getPrintReceipts({ odrg_id: row.odrg_id }).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("打印成功！");
        tableRef.value.tableLoad();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }

  onMounted(() => {
    tableRef.value.tableLoad();
  });
</script>

<style lang="scss">
  .catering-orders {
    font-family: "Source Han Sans CN";

    .xs-m-t {
      margin-top: 5px;
    }

    .content {
      padding: 20px;
    }

    .order-info {
      .catering-infor-title {
        color: #92979E;
        width: 80px;
        text-align: left;
        margin-bottom: 10px;
        flex-shrink: 0;
      }

      .order-content {
        background-color: #f9f9fb;
        padding: 10px;
      }

      .order-fee {
        color: var(--text-color);
        margin-top: 10px;
      }

      .order-fee-title {
        margin-right: 15px;
      }

      .order-fee-num {
        text-align: end;
      }

      .catering-infor-content {
        color: #262C30;
      }

      .order-table .el-table__cell {
        padding: 9px 0;
      }

      .order-table th.el-table__cell {
        background-color: var(--search-bg-color);
        padding: 9px 0;
        font-size: 15px;
        color: var(--text-color);
      }

      .order-table td.el-table__cell div span {
        font-size: 14px;
      }

      .total-num {
        font-size: 18px;
      }

      .text-blue {
        color: var(--theme-color);
      }
    }
  }
</style>